.info-page-card {
  width: 100%;
  height: fit-content;

  &:not(.full-width) {
    border-right: 1px solid #F2F2F2;
    border-left: 1px solid #F2F2F2;
  }

  border-bottom: 2px solid #F2F2F2;
  padding: .5rem 1rem;
  display: inline-block;
  break-inside: avoid;

  &.web {
    @media all and (min-width: 992px) {
      //Apply is used so that the border is not overwritten by the default border of the card (higher specificity)
      @apply rounded-lg border-2 border-solid;
      border-color: #c4c4c4;
    }

    .header {
      .image {
        aspect-ratio: 1;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;

      .preview {
        max-height: 7.5rem;
        min-height: 7.5rem;
        text-overflow: ellipsis;
        position: relative;
        overflow: hidden;
        margin-bottom: 1rem;
      }

      .preview::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
        content: '\200C';
        display: block;
        position: absolute;
        right: 0;
        top: 75%;
        width: 100%;
        height: 25%;
        text-align: center;
      }
    }
  }

  .header {
    width: 100%;

    .length {
      display: flex;
      margin-bottom: .5rem;
      margin-top: .5rem;

      .background {
        min-width: 106px;
      }

      p {
        margin: 0 0 0 .5rem;
      }
    }

    .image {
      position: relative;

      .company-image {
        img {
          width: 24px;
          object-fit: contain;
          height: 24px;
        }
      }

      .progress-symbol {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 0.2rem .2rem .2rem .3rem;
        background: white;
        border-bottom-left-radius: 0.75rem;

        img {
          height: 1rem;
          border-radius: 0.25rem;
        }
      }

      img.cover {
        width: 100%;
        object-fit: cover;
        // We want the image to be "maximally a square". Since there is a 1 px border and 1rem padding on each side, we need to subtract 2rem and 2px from the width and height.
        max-height: calc(50vw - 2rem - 2px);
      }
    }

    .bottom-header {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      bottom: 4px;

      .category-overlay {
        color: white;
        background: #5F4AE9;
        width: fit-content;
        display: flex;
        align-items: center;
        padding: .5rem .5rem .5rem 1rem;
        border-radius: 0 .3rem 0 0;
      }
    }
  }


  .content {
    padding: .5rem 0;
    hyphens: auto;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;

    .title {
      font-size: 1rem;
      font-weight: 900;
    }

    p {
      font-size: 0.875rem;
      margin: .5rem 0;
    }
  }
}

.info-page-card.web {
  @apply rounded-lg border-2 border-solid;
  border-color: #c4c4c4;
}

@media all and (min-width: 50px) {
  .info-page-card:not(.full-width) {
    padding: 0.5rem 0.5rem
  }
}

@media all and (min-width: 300px) {
  .info-page-card:not(.full-width) {
    padding: 0.5rem 0.75rem
  }
}

@media all and (min-width: 400px) {
  .info-page-card:not(.full-width) {
    padding: 0.5rem 1.0rem
  }
}

// Font sizes adjusted, so that the word "vertrouwenspersonen" in a feed title fits...
@media all and (min-width: 50px) {
  .info-page-card:not(.full-width) .content .title {
    font-size: 0.6rem;
  }
}

@media all and (min-width: 300px) {
  .info-page-card:not(.full-width) .content .title {
    font-size: 0.75rem;
  }
}

@media all and (min-width: 360px) {
  .info-page-card:not(.full-width) .content .title {
    font-size: 0.875rem;
  }
}

@media all and (min-width: 420px) {
  .info-page-card:not(.full-width) .content .title {
    font-size: 1.0rem;
  }
}

@media all and (min-width: 500px) {
  .info-page-card:not(.full-width) .content .title {
    font-size: 1.25rem;
  }
}

@media all and (min-width: 50px) {
  .info-page-card:not(.full-width) .content p {
    font-size: 0.6rem;
  }
}

@media all and (min-width: 300px) {
  .info-page-card:not(.full-width) .content p {
    font-size: 0.65rem;
  }
}

@media all and (min-width: 360px) {
  .info-page-card:not(.full-width) .content p {
    font-size: 0.75rem;
  }
}

@media all and (min-width: 420px) {
  .info-page-card:not(.full-width) .content p {
    font-size: 0.875rem;
  }
}

@media all and (min-width: 500px) {
  .info-page-card:not(.full-width) .content p {
    font-size: 1rem;
  }
}

.info-page-card.full-width {
  .header .image .cover {
    // We want a 16:9 maximum
    max-height: calc((100vw - 4rem) * 9 / 16)
  }
}

//.info-page-card-horizontal {
//  width: 100%;
//  height: fit-content;
//  background: white;
//  border-radius: .2rem;
//  display: flex;
//  border-bottom: 2px solid #F2F2F2;
//  padding: 1rem 0;
//
//  .info-page-card {
//    max-height: 225px;
//  }
//
//  .flex-column {
//    flex-direction: column;
//  }
//
//  .header {
//    //width: 33.33%;
//
//    .bottom-header .category-overlay {
//      display: none;
//    }
//
//    .background {
//      height: 100% !important;
//    }
//  }
//
//  .content {
//    //width: 66.66%;
//    padding: .7rem 0 .7rem .7rem;
//  }
//}

.grid {
  column-count: 2;
  column-gap: 0;
  width: 100%;
}

.grid-full {
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 100%);
  color: hsla(0, 0%, 0%, .87);
}

.col-grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.col-grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
