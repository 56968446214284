/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

//@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Museo Sans;
  src: url(/assets/fonts/MuseoSans-100.woff2) format("woff2"),
  url(/assets/fonts/MuseoSans-100.ttf) format("ttf");
  font-weight: 100;
}

@font-face {
  font-family: Museo Sans;
  src: url(/assets/fonts/MuseoSans-300.woff2) format("woff2"),
  url(/assets/fonts/MuseoSans-300.ttf) format("ttf");
  font-weight: 300;
}

@font-face {
  font-family: Museo Sans;
  src: url(/assets/fonts/MuseoSans_500.woff2) format("woff2"),
  url(/assets/fonts/MuseoSans_500.ttf) format("ttf");
  font-weight: 500;
}

@font-face {
  font-family: Museo Sans;
  src: url(/assets/fonts/MuseoSans_700.woff2) format("woff2"),
  url(/assets/fonts/MuseoSans_700.ttf) format("ttf");
  font-weight: 700;
}

@font-face {
  font-family: Museo Sans;
  src: url(/assets/fonts/MuseoSans_900.woff2) format("woff2"),
  url(/assets/fonts/MuseoSans_900.ttf) format("ttf");
  font-weight: 900;
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

///* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/** Animation CSS */
@import 'animate.css';

@import "theme/bootstrap-utilities.css";

@import 'info-cards';
@import "mixins";

.text-primary {
  color: var(--ion-color-primary) !important;
}

.text-success {
  color: var(--ion-color-success) !important;
}

.text-warning {
  color: var(--ion-color-warning) !important;
}

.text-danger {
  color: var(--ion-color-danger) !important;
}

.no-link-style {
  text-decoration: inherit;
  color: inherit;
}

.fits-spinner {
  height: 44px;
}

.hidden {
  opacity: 0;
}

.brand-gradient {
  --background: radial-gradient(ellipse at top left, var(--ion-color-secondary), var(--ion-color-primary) 50%, var(--ion-color-primary) 100%);
  @include brand-background;
  //--background: var(--gradient, radial-gradient(105.89% 144.73% at 4.63% -0.00%, var(--ion-color-secondary) 0%, var(--ion-color-primary) 61.98%));

}

.brand-gradient-black {
  --background: radial-gradient(ellipse at top left, #5B5B5C, #5B5B5C 50%, #5B5B5C 100%);
  background: radial-gradient(ellipse at top left, #5B5B5C, #5B5B5C 50%, #5B5B5C 100%);
  //--background: var(--gradient, radial-gradient(105.89% 144.73% at 4.63% -0.00%, var(--ion-color-secondary) 0%, var(--ion-color-primary) 61.98%));

}

.app-mode-switch {
  //height: 125%;
  ////@extend .brand-gradient-black;
  //--background: white;
  //background: white;
  //color: #5B5B5C;
  border-left: 2px solid #D2D2D2 !important;
}

$primary-color: white;
.info-content {
  position: relative;
  top: -1rem;
  z-index: 10;
  border-radius: .5rem .5rem 0 0;

  .border-radius {
    border-radius: .5rem .5rem 0 0;
  }

  ion-toolbar {
    background: $primary-color;
    padding: 0 1rem;
  }

  ion-title {
    margin-top: .5rem;
  }
}

.info-content.web {
  top: 0;
}


.grayed-out {
  font-size: .8rem;
  @media (min-width: 400px) {
    font-size: 1rem;
  }
  opacity: .6;
}

.safe-area-m-top {
  margin-top: env(safe-area-inset-top) !important;
}

.safe-area-m-bottom {
  margin-bottom: env(safe-area-inset-bottom) !important;
}

.safe-area-p-top {
  padding-top: env(safe-area-inset-top) !important;
  padding-top: constant(safe-area-inset-top) !important;
}

.safe-area-top {
  top: env(safe-area-inset-top) !important;
  top: constant(safe-area-inset-top) !important;
}

h1 {
  font-size: 1.5rem;
}

.btn-primary {
  padding: 1rem 1.2rem;
  background: var(--gradient, radial-gradient(105.89% 144.73% at 4.63% -0.00%, #FFC700 0%, #F15B22 61.98%));
  border-radius: .5rem;
  color: white;
  font-weight: normal;
  font-size: 1.2rem;
}

.btn-primary-hollow {
  padding: .8rem 1.2rem;
  background: white;
  border: 3px solid var(--ion-color-primary);
  border-radius: .5rem;
  font-weight: normal;
  font-size: 1.2rem;
  color: var(--ion-color-primary);
}

.btn-company {
  padding: 1rem 1.2rem;
  background: var(--gradient, radial-gradient(105.89% 144.73% at 4.63% -0.00%, var(--ion-color-company) 0%, var(--ion-color-company) 61.98%));
  border-radius: .5rem;
  color: white;
  font-weight: normal;
  font-size: 1.2rem;
}

.btn-company-hollow {
  padding: .5rem 1rem;
  background: white;
  gap: .5rem;
  border: 3px solid var(--ion-color-company);
  border-radius: .5rem;
  font-weight: normal;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-company);

  ion-icon {
    @media (min-width: 360px) {
      margin-right: .5rem;
    }
  }
}


.row {
  display: flex;
  flex-direction: row;

}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.gap-1 {
  gap: 1rem;
}

.toolbar-container {
  background: var(--ion-color-light) !important;
}

ion-input, ion-textarea {
  //width: 100%;
  border: 3px solid var(--ion-color-medium);
  border-radius: .5rem;
  margin: .5rem 0;
  padding: 0 .5rem;
  background: #F2F2F2;

  --highlight-color: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-primary);
  --highlight-color-invalid: var(--ion-color-danger);
}

ion-textarea {
  min-height: 175px !important;
}

button, ion-button {
  &:hover {
    cursor: pointer;
  }
}

.ion-tab-bar {
  padding: 1rem;
}

ion-tab-bar {
  height: 80px;
}

.d-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ion-app {
  @media(min-width: 1600px) {
    margin: 0 auto;
    //max-width: 1600px;
  }
}

.button-outline {
  --border-width: 3px !important;
}

input[type=text], input[type=email], input[type=password], input[type=number], input[type=phone], textarea {
  padding-left: .5rem !important;


  &:focus {
    border-color: var(--ion-color-primary) !important;
  }
}

.page {
  padding: 2rem 1rem 1rem;
  margin-top: var(--ion-safe-area-top)
}

.has-focus {
  border-color: var(--ion-color-primary) !important;
}

.radio-option {
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
  cursor: pointer;

  .circle {
    border: 3px solid var(--ion-color-medium);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    min-width: 24px;
    display: block;
    margin-right: .5rem;
  }

  .content {
    p {
      color: var(--ion-color-medium);
      font-style: italic;
      font-weight: 300;
      margin-block-start: 0.5rem;
      margin-block-end: 0;
    }
  }
}

.radio-option + .radio-option {
  margin-bottom: 0;
}

.gray-btn {
  border: 3px solid var(--ion-color-medium);
  color: var(--ion-color-medium);
  //width: 100%;
  border-radius: 8px;
  --background: white !important;
  --border-radius: 8px;
  margin-inline: 0;
}

h1 {
  font-weight: 900;
  display: flex;
  align-items: center;


  ion-icon {
    padding: .5rem;
    color: var(--ion-color-primary);
    font-size: 2rem;
  }
}

.d-flex-align {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios {
  justify-content: flex-start !important;
}

ion-select::part(container) {
  width: 100%;
}

.preformatted {
  white-space: pre-line;
}

.cta-space {
  height: 6rem;
}

.arrow-back {
  ion-icon {
    font-size: 2rem;
  }

  cursor: pointer;
}

.contact-by {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  margin: 1rem 0;

  .email, .phone {
    //max-height: 47px;
    padding: .2rem;
    @media (min-width: 330px) {
      padding: .7rem
    }
    @media (min-width: 410px) {
      width: 50%;
    }
    text-align: center;
    cursor: pointer;
  }

  .email {
    border-radius: 100px 0 0 100px;
    border: 3px solid var(--ion-color-primary);
  }

  .phone {
    border-radius: 0 100px 100px 0;
    border: 3px solid var(--ion-color-primary);
  }

  .active {
    background: var(--ion-color-primary);
    color: white;
  }
}

.magic-logo {
  width: 100%;
  position: absolute;
  top: -2rem;
  left: 50%;

  z-index: 100;


  img {
    transform: translateX(-50%);
    width: 4.5rem;
  }
}

.font-1-rem {
  font-size: 1rem;
}

.p-1-rem {
  padding: 1rem;
}

.info-page-header {
  margin-top: 2.25rem;
  margin-bottom: 0.5rem;
}

.info-page-header-subtext {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  font-size: .8rem;
  @media (min-width: 400px) {
    font-size: 1rem;
  }
  opacity: .6;
}

.report-page-header {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1;

  font-weight: 900;
  display: flex;
  align-items: center;

  ion-icon {
    padding: 0 .5rem;
    color: var(--ion-color-primary);
    font-size: 2rem;
  }
}

.report-page-subheader {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  font-size: .8rem;
  @media (min-width: 400px) {
    font-size: 1rem;
  }
  opacity: .6;
}

.input-hint {
  font-size: .75rem;
  color: var(--ion-color-medium);
  margin-top: -0.25rem;
  margin-bottom: 0.25rem;
  padding-right: 0.2rem;
  text-align: right;
  width: 100%;
}

.option-grayed-out {
  opacity: 0.6;
  pointer-events: none;
  color: #c2c2c2
}

.ql-align-right {
  text-align: right;
}

.ql-align-left {
  text-align: left;
}

.ql-align-center {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-blocks-container * {
  margin: 0;
  //padding: 0;
}

.preview-blocks-container ol li[data-list='ordered'] {
  list-style-type: decimal;
}

.preview-blocks-container ul li,
.preview-blocks-container ol li[data-list='bullet'] {
  list-style-type: disc;
}

@media (min-width: 500px) {
  .web.box {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .web.box {
    width: 100%;
  }
}

.web.box {
  min-height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
