// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #FF5A00;
  --ion-color-primary-rgb: 255, 90, 0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: darken(#FF5A00, 10%);
  --ion-color-primary-tint: lighten(#FF5A00, 10%);

  /** secondary **/
  --ion-color-secondary: #FBBA00;
  --ion-color-secondary-rgb: 251, 186, 0;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: darken(#FBBA00, 20%);
  --ion-color-secondary-tint: lighten(#FBBA00, 20%);

  /** tertiary **/
  --ion-color-tertiary: #BC1455;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: lighten(#BC1455, 10%);
  --ion-color-tertiary-tint: lighten(#BC1455, 20%);

  /** success **/
  --ion-color-success: #23A596;
  --ion-color-success-rgb: 35, 165, 150;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: darken(#23A596, 15%);
  --ion-color-success-tint: darken(#23A596, 30%);

  /** warning **/
  --ion-color-warning: #FBBA00;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: darken(#FBBA00, 20%);;
  --ion-color-warning-tint: lighten(#FBBA00, 20%);

  /** danger **/
  --ion-color-danger: #BC1455;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: lighten(#BC1455, 10%);
  --ion-color-danger-tint: lighten(#BC1455, 20%);

  /** dark **/
  --ion-color-dark: #000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #5B5B5B;
  --ion-color-medium-rgb: 91, 91, 91;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: darken(#5B5B5B, 20%);
  --ion-color-medium-tint: lighten(#5B5B5B, 20%);

  /** light **/
  --ion-color-light: #fff;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e8e8e8;
  --ion-color-light-tint: #ccc;


  /** Theme overrides **/
  --ion-background-color: var(--ion-color-light);
  --ion-font-family: Museo Sans, sans-serif;

  /** default company color **/
  --ion-color-company: #5F4AE9;
}

ion-button {
  --background: radial-gradient(ellipse at top left, var(--ion-color-secondary), var(--ion-color-primary) 50%, var(--ion-color-primary) 100%);
  --border-radius: 8px;
  text-transform: inherit;
  font-size: 1.125rem;
}

ion-button.w-100 {
  // Prevents buttons with 100% width from not being centered
  margin-inline: 0;
}

.button-outline {
  --background: --ion-color-light;
}

.button-clear {
  --background: transparent !important;
}

.header-md {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

// Custom tab styling
$tab-bar-height: 80px;
$report-tab-offset: 0px;

ion-tab-bar {
  height: $tab-bar-height;
  //box-sizing: border-box !important;
  padding-top: $report-tab-offset;
  //background: none;
  border: none;
  align-items: flex-end;

  --background: var(--ion-color-light);

  ion-tab-button.app-mode-switch {
    //height: calc($tab-bar-height + $report-tab-offset);
    //background-color: var(--ion-color-medium-shade) !important;
    //color: var(--ion-color-primary-contrast);
    //border-radius: 1rem 0rem 0 0;
    //
    //--color-selected: var(--ion-color-primary-contrast);
    border-left: 2px solid #D2D2D2 !important;
  }

  ion-tab-button {
    --padding-start: 0.3rem;
    --padding-end: 0.3rem;

    ion-label {
      height: 1.75rem;
      line-height: 1 !important;
      display: flex;
      align-items: center;
      white-space: normal !important;
      font-size: 10px;
      margin-bottom: 0 !important;
    }
  }
}

// Since we removed the original tab bar background (so that we can add something to extend beyond it), we need to add a background to the tab bar content.
ion-tab-bar::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $tab-bar-height;
  z-index: -1;
  background: var(--background);
  //border-top: var(--border);
}


//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #ec6707;
//    --ion-color-primary-rgb: 236, 103, 7;
//    --ion-color-primary-contrast: #000000;
//    --ion-color-primary-contrast-rgb: 0, 0, 0;
//    --ion-color-primary-shade: #d05b06;
//    --ion-color-primary-tint: #ee7620;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
